<template>
  <div style="padding-bottom: 0">
    <h1 class="page_title mb-5">{{ $route.meta.title }}</h1>

    <ValidationObserver v-slot="{ invalid }">
      <div class="wrap overflow-hidden">
        <!-- 商品列表 -->
        <div class="w-full md:w-2/3 flex flex-col relative">
          <!-- 訂單確認頁 start -->
          <div
            class="w-full h-full bg-white absolute top-0 left-0 z-10 flex flex-col transform transition-transform duration-500"
            :class="[orderConfirmToggler ? 'translate-y-0' : 'translate-y-full']"
          >
            <!-- header -->
            <div style="height: 74px" class="border-b-2 border-gray-background flex items-center px-4 flex-shrink-0">
              <vs-button @click="orderConfirmToggler = false" transparent>
                <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
              </vs-button>
              <h2 class="h2 ml-2">訂單確認</h2>
            </div>
            <!-- 訂單確認內容區塊 -->
            <section class="flex-grow py-4 overflow-auto w-full">
              <section class="space-y-6 max-w-3xl m-auto pb-44 md:pb-4">
                <!-- 顧客資料 -->
                <section class="space-y-4 px-4">
                  <h2 class="h2">顧客資料</h2>
                  <div v-if="orderConfirm.memberShow" class="member_box relative">
                    <!-- absolute delete -->
                    <button
                      @click.stop="customerUnChoose"
                      class="
                        absolute
                        w-6
                        h-6
                        bg-red-400
                        rounded-full
                        top-0
                        right-0
                        transform
                        translate-x-1/2
                        -translate-y-1/2
                        flex
                        items-center
                        justify-center
                        shadow-md
                      "
                    >
                      <span class="w-4 h-1 bg-white rounded-full"></span>
                    </button>
                    <p v-if="!orderConfirm.memberShow" class="text-success">指定會員</p>
                    <div v-else class="member_box_inner">
                      <div class="flex items-center justify-between w-full">
                        <h3 class="h3">指定會員</h3>
                        <vs-button @click="edit_member" size="mini" color="success" transparent>更換會員</vs-button>
                      </div>
                      <div class="text-left">
                        <p class="break_word">{{ orderConfirm.member_show_data.uname }}</p>
                        <p class="break_word">{{ orderConfirm.member_show_data.mp }}</p>
                        <p class="break_word">{{ orderConfirm.member_show_data.email }}</p>
                        <p v-if="orderConfirm.member_show_data.vip && orderConfirm.member_show_data.vip !== '無'" class="break_word">
                          {{ orderConfirm.member_show_data.vip }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div v-else class="space-y-4">
                    <div>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">姓名</h4>
                        <vs-button @click="edit_member" size="mini" color="success" transparent>
                          <span class="text-sm">指定會員</span>
                        </vs-button>
                      </div>
                      <ValidationProvider tag="div" class="relative" vid="顧客資料姓名" name="姓名" rules="max:30" v-slot="{ errors }">
                        <vs-input v-model.trim="orderConfirm.member_show_data.uname" />
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                    <div>
                      <h4 class="h4 mb-1">電話號碼</h4>
                      <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
                        <div class="sm:col-span-1">
                          <vs-select
                            v-model="orderConfirm.member_show_data.phone_code"
                            placeholder="請選擇區碼"
                            filter
                            v-if="countries.length > 0"
                          >
                            <vs-option
                              v-for="country in countries"
                              :key="country.en"
                              :label="`${country.code} (${country.cn} ${country.en})`"
                              :value="`${country.code},${country.en}`"
                              >{{ country.code }} ({{ country.cn }})</vs-option
                            >
                          </vs-select>
                        </div>

                        <div class="sm:col-span-2">
                          <ValidationProvider
                            tag="div"
                            vid="顧客資料電話號碼"
                            class="relative"
                            name="電話號碼"
                            rules="numeric|max:20"
                            v-slot="{ errors }"
                          >
                            <vs-input
                              type="number"
                              placeholder="不需再輸入區碼，例如：920456789"
                              v-model.number="orderConfirm.member_show_data.mp"
                            />
                            <errorDiv>{{ errors[0] }}</errorDiv>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h4 class="h4 mb-1">電子信箱</h4>
                      <ValidationProvider
                        tag="div"
                        vid="顧客資料電子信箱"
                        class="relative"
                        name="電子信箱"
                        rules="email|max:150"
                        v-slot="{ errors }"
                      >
                        <vs-input v-model.trim="orderConfirm.member_show_data.email" />
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                  </div>
                </section>

                <hr />

                <section class="px-4">
                  <h2 class="h2 mb-4">送貨方式</h2>

                  <div v-if="orderConfirm.thirdData.arr2 && orderConfirm.thirdData.arr2.length > 0">
                    <vs-select @change="handleShippingSelect" v-model="orderConfirm.shippingVal" placeholder="請選擇送貨方式">
                      <vs-option value="不需送貨" label="不需送貨">不需送貨</vs-option>
                      <vs-option v-for="item in orderConfirm.thirdData.arr2" :key="item.slno" :value="item.slno" :label="item.title"
                        >{{ item.title }}
                      </vs-option>
                    </vs-select>
                  </div>
                </section>

                <!-- 選擇超商門市 -->
                <transition name="fade">
                  <section class="px-4" v-if="orderConfirm.shipping_show === 'convenience_delivery'">
                    <div class="rounded-xl border-2 border-gray-background overflow-hidden">
                      <div class="p-4">
                        <h2 class="h2 mb-4">取貨資料</h2>
                        <div class="space-y-4">
                          <div>
                            <div class="flex items-center justify-between mb-1">
                              <h4 class="h4">姓名 *</h4>
                            </div>
                            <ValidationProvider
                              tag="div"
                              vid="取貨資料姓名"
                              class="relative"
                              name="姓名"
                              rules="required|max:30"
                              v-slot="{ errors }"
                            >
                              <vs-input
                                placeholder="若非取貨人本人姓名，可能導致無法取貨。"
                                v-model.trim="orderConfirm.address_convenience.runame"
                              />
                              <errorDiv>{{ errors[0] }}</errorDiv>
                            </ValidationProvider>
                          </div>
                          <div>
                            <h4 class="h4 mb-1">電話號碼 *</h4>
                            <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
                              <div class="sm:col-span-1">
                                <vs-select
                                  v-model="orderConfirm.address_convenience.phone_code"
                                  placeholder="請選擇區碼"
                                  filter
                                  v-if="countries.length > 0"
                                >
                                  <vs-option
                                    v-for="country in countries"
                                    :key="country.en"
                                    :label="`${country.code} (${country.cn} ${country.en})`"
                                    :value="`${country.code},${country.en}`"
                                    >{{ country.code }} ({{ country.cn }})</vs-option
                                  >
                                </vs-select>
                              </div>

                              <div class="sm:col-span-2">
                                <ValidationProvider
                                  tag="div"
                                  class="relative"
                                  name="電話號碼"
                                  vid="取貨資料電話號碼"
                                  rules="required|numeric|max:20"
                                  v-slot="{ errors }"
                                >
                                  <vs-input
                                    type="number"
                                    placeholder="不需再輸入區碼，例如：920456789"
                                    v-model.number="orderConfirm.address_convenience.rmp"
                                  />
                                  <errorDiv>{{ errors[0] }}</errorDiv>
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                          <div>
                            <h4 class="h4 mb-1">電子信箱 *</h4>
                            <ValidationProvider
                              tag="div"
                              class="relative"
                              name="電子信箱"
                              vid="取貨資料電子信箱"
                              rules="required|email|max:150"
                              v-slot="{ errors }"
                            >
                              <vs-input v-model.trim="orderConfirm.address_convenience.remail" />
                              <errorDiv>{{ errors[0] }}</errorDiv>
                            </ValidationProvider>
                          </div>
                          <div v-if="convenienceData.sname">
                            <h4 class="h4 mb-1">門市店名</h4>
                            <p>{{ convenienceData.sname }}</p>
                          </div>
                          <div v-if="convenienceData.sid">
                            <h4 class="h4 mb-1">門市代號</h4>
                            <p>{{ convenienceData.sid }}</p>
                          </div>
                        </div>
                      </div>
                      <button class="bg-success text-white w-full h-10" @click="getStorePopup">
                        <span v-if="!convenienceData.sname">選擇超商門市</span>
                        <span v-else>更換超商門市</span>
                      </button>
                    </div>
                  </section>
                </transition>

                <!-- 收件地址 -->
                <transition name="fade">
                  <section class="px-4" v-if="orderConfirm.shipping_show === 'home_delivery'">
                    <div class="rounded-xl border-2 border-gray-background overflow-hidden p-4">
                      <h2 class="h2 mb-4">收件地址</h2>
                      <div class="grid grid-cols-2 gap-4 my-4">
                        <div class="col-span-2">
                          <h4 class="h4 mb-1">姓名 *</h4>
                          <ValidationProvider
                            tag="div"
                            class="relative"
                            vid="收件地址姓名"
                            name="姓名"
                            rules="required|max:30"
                            v-slot="{ errors }"
                          >
                            <vs-input v-model.trim="orderConfirm.address_homeDelivery.runame" />
                            <errorDiv>{{ errors[0] }}</errorDiv>
                          </ValidationProvider>
                        </div>
                        <div class="col-span-2">
                          <h4 class="h4 mb-1">電話號碼 *</h4>
                          <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
                            <div class="sm:col-span-1">
                              <vs-select
                                placeholder="請選擇區碼"
                                filter
                                v-model.trim="orderConfirm.address_homeDelivery.phone_code"
                                v-if="countries.length > 0"
                              >
                                <vs-option
                                  v-for="country in countries"
                                  :key="country.en"
                                  :label="`${country.code} (${country.cn} ${country.en})`"
                                  :value="`${country.code},${country.en}`"
                                  >{{ country.code }} ({{ country.cn }})</vs-option
                                >
                              </vs-select>
                            </div>

                            <div class="sm:col-span-2">
                              <ValidationProvider
                                tag="div"
                                class="relative"
                                name="電話號碼"
                                vid="收件地址電話號碼"
                                rules="required|numeric|max:20"
                                v-slot="{ errors }"
                              >
                                <vs-input placeholder="不需再輸入區碼，例如：920456789" v-model="orderConfirm.address_homeDelivery.rmp" />
                                <errorDiv>{{ errors[0] }}</errorDiv>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <div class="col-span-2">
                          <h4 class="h4 mb-1">電子信箱 *</h4>
                          <ValidationProvider
                            tag="div"
                            class="relative"
                            vid="收件地址電子信箱"
                            name="電子信箱"
                            rules="required|max:150|email"
                            v-slot="{ errors }"
                          >
                            <vs-input v-model="orderConfirm.address_homeDelivery.remail" />
                            <errorDiv>{{ errors[0] }}</errorDiv>
                          </ValidationProvider>
                        </div>

                        <div class="">
                          <h4 class="h4 mb-1">國家/地區 *</h4>
                          <vs-select
                            placeholder="搜尋國家/地區"
                            filter
                            v-model="orderConfirm.address_homeDelivery.rshit"
                            v-if="countries.length > 0"
                          >
                            <vs-option
                              v-for="country in countries"
                              :key="country.en"
                              :label="country.cn + ' ' + country.en"
                              :value="country.cn + ' ' + country.en"
                              >{{ country.cn }} {{ country.en }}</vs-option
                            >
                          </vs-select>
                        </div>
                        <div>
                          <h4 class="h4 mb-1">縣市 *</h4>
                          <!-- 縣市(若為台灣，選單) -->
                          <ValidationProvider
                            v-if="orderConfirm.address_homeDelivery.rshit === '台灣 Taiwan'"
                            tag="div"
                            vid="收件地址縣市"
                            class="relative"
                            name="縣市"
                            rules="required|max:20"
                            v-slot="{ errors }"
                          >
                            <vs-select @change="cityHandler" class="w-full" v-model="orderConfirm.address_homeDelivery.rcity">
                              <vs-option v-for="city in TW_districts" :key="city.name" :label="city.name" :value="city.name">{{
                                city.name
                              }}</vs-option>
                            </vs-select>
                            <errorDiv>{{ errors[0] }}</errorDiv>
                          </ValidationProvider>

                          <ValidationProvider
                            v-else
                            tag="div"
                            vid="收件地址縣市"
                            class="relative"
                            name="縣市"
                            rules="required|max:20"
                            v-slot="{ errors }"
                          >
                            <vs-input v-model="orderConfirm.address_homeDelivery.rcity" />
                            <errorDiv>{{ errors[0] }}</errorDiv>
                          </ValidationProvider>
                        </div>

                        <div>
                          <h4 class="h4 mb-1">鄉鎮市區 *</h4>

                          <ValidationProvider
                            v-if="_district && orderConfirm.address_homeDelivery.rshit === '台灣 Taiwan'"
                            tag="div"
                            vid="收件地址鄉鎮市區"
                            class="relative"
                            name="鄉鎮市區"
                            rules="required|max:20"
                            v-slot="{ errors }"
                          >
                            <!-- 鄉鎮市區(若為台灣，選單) -->
                            <vs-select
                              :key="_district.districts.length"
                              @change="districtHandler"
                              class="w-full"
                              v-model="orderConfirm.address_homeDelivery.rarea"
                            >
                              <vs-option
                                v-for="district in _district.districts"
                                :key="district.name"
                                :label="district.name"
                                :value="district.name"
                                >{{ district.name }}</vs-option
                              >
                            </vs-select>
                            <errorDiv>{{ errors[0] }}</errorDiv>
                          </ValidationProvider>

                          <ValidationProvider
                            v-else
                            tag="div"
                            vid="收件地址鄉鎮市區"
                            class="relative"
                            name="鄉鎮市區"
                            rules="required|max:20"
                            v-slot="{ errors }"
                          >
                            <vs-input v-model="orderConfirm.address_homeDelivery.rarea" />
                            <errorDiv>{{ errors[0] }}</errorDiv>
                          </ValidationProvider>
                        </div>

                        <div>
                          <h4 class="h4 mb-1">郵遞區號 *</h4>
                          <ValidationProvider
                            tag="div"
                            vid="收件地址郵遞區號"
                            class="relative"
                            name="郵遞區號"
                            rules="required|max:20"
                            v-slot="{ errors }"
                          >
                            <vs-input v-model="orderConfirm.address_homeDelivery.rzipcode" />
                            <errorDiv>{{ errors[0] }}</errorDiv>
                          </ValidationProvider>
                        </div>

                        <div class="col-span-2">
                          <h4 class="h4 mb-1">地址一 *</h4>
                          <ValidationProvider
                            tag="div"
                            class="relative"
                            vid="收件地址地址一"
                            name="地址一"
                            rules="required|max:100"
                            v-slot="{ errors }"
                          >
                            <vs-input v-model="orderConfirm.address_homeDelivery.raddress1" />
                            <errorDiv>{{ errors[0] }}</errorDiv>
                          </ValidationProvider>
                        </div>
                        <div class="col-span-2">
                          <h4 class="h4 mb-1">地址二</h4>
                          <ValidationProvider
                            tag="div"
                            class="relative"
                            vid="收件地址地址二"
                            name="地址二"
                            rules="max:100"
                            v-slot="{ errors }"
                          >
                            <vs-input v-model="orderConfirm.address_homeDelivery.raddress2" />
                            <errorDiv>{{ errors[0] }}</errorDiv>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </section>
                </transition>

                <hr />

                <section class="px-4">
                  <h2 class="h2 mb-4">付款方式</h2>

                  <div v-if="orderConfirm.payment_show">
                    <div v-if="orderConfirm.thirdData.arr1 && orderConfirm.thirdData.arr1.length > 0">
                      <vs-select @change="handlePaymentSelect" v-model="orderConfirm.paymentVal" placeholder="請選擇付款方式">
                        <vs-option value="cash" label="線下現金付款">線下現金付款</vs-option>
                        <vs-option value="offline_creditCard" label="線下信用卡付款">線下信用卡付款</vs-option>
                        <vs-option v-for="item in orderConfirm.thirdData.arr1" :key="item.spno" :value="item.spno" :label="item.title"
                          >{{ item.title }}
                        </vs-option>
                      </vs-select>
                    </div>
                  </div>

                  <p v-else>貨到付款 / 取貨付款</p>
                </section>

                <hr />

                <section class="px-4">
                  <h2 class="h2 mb-4">訂單備註</h2>
                  <myTextarea placeholder="※ 會員看不到此資訊" height="100px" v-model="orderConfirm.note"></myTextarea>
                </section>
              </section>
            </section>
          </div>
          <!-- 訂單確認頁 end -->
          <!-- 搜尋商品 input -->
          <div class="p-4 border-b-2 border-gray-background">
            <vs-input @blur="search" @keypress.enter="search" placeholder="搜尋商品標題" v-model="searchTitle"></vs-input>
          </div>
          <div class="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 auto-rows-min gap-4 p-4 flex-grow overflow-auto pb-56 md:pb-16">
            <div
              v-for="(item, index) in products"
              :key="index"
              :class="[dropdownIndex === index ? 'z-10' : '']"
              @click="showDropdown(index)"
            >
              <div class="image_item aspect-ratio-3-4_global_style">
                <div class="image_item_content">
                  <div class="image_item_inner aspect-ratio-1-1_global_style">
                    <img v-if="item.img" class="image" :src="item.img" />
                    <div v-else class="image flex items-center justify-center">
                      <svg-icon icon-class="product_tag" className="gray-border xxl"></svg-icon>
                    </div>
                    <div class="product_name">
                      <p class="text-limit-2">{{ item.title }}</p>
                    </div>
                  </div>
                </div>
                <transition name="fade">
                  <dropdown style="width: -webkit-fill-available" class="border-2" v-if="dropdownIndex === index">
                    <div class="flex flex-col divide-y divide-gray-divide">
                      <button
                        @click.stop="addToCart(item, list)"
                        v-for="list in item.arr"
                        :key="list.spsno"
                        class="py-2 text-gray-text_light hover:font-bold transition-all duration-300"
                      >
                        <div v-if="!list.spec1 && !list.spec2 && !list.spec3">無規格，點此加入</div>
                        <div v-else>
                          <span>{{ list.spec1 }}</span>
                          <span v-if="list.spec2"> / {{ list.spec2 }}</span>
                          <span v-if="list.spec3"> / {{ list.spec3 }}</span>
                        </div>
                      </button>
                    </div>
                  </dropdown>
                </transition>
              </div>
            </div>
          </div>
        </div>
        <!-- 明細列表 -->
        <div class="order_summary_wrap h-screen" :class="[mobile_summary_toggler ? 'max-h-screen' : 'max-h-36 md:max-h-full ']">
          <div style="height: 74px" class="border-b-2 border-gray-background flex items-center justify-between px-4 relative flex-shrink-0">
            <!-- 手機版展開按鈕 -->
            <button
              @click="mobile_summary_toggler = !mobile_summary_toggler"
              class="md:hidden absolute left-1/2 -bottom-2 transform -translate-x-1/2"
            >
              <span v-if="!mobile_summary_toggler">展開</span>
              <span v-else>收合</span>
            </button>
            <!-- 手機版展開按鈕 end -->
            <h2 class="h2">訂單項目({{ orderSummary.cart.length }})</h2>
            <vs-button v-if="orderSummary.cart.length" color="danger" size="mini" transparent @click="orderSummary.cart = []"
              >清空</vs-button
            >
          </div>
          <!-- 商品項目 *手機版未展開時隱藏 -->
          <transition-group
            name="fade"
            tag="div"
            class="flex-grow flex flex-col overflow-x-hidden overflow-y-auto py-4 space-y-4"
            :class="{ 'hidden md:flex': !mobile_summary_toggler }"
          >
            <div
              v-for="(item, index) in orderSummary.cart"
              :key="item.details.spsno"
              class="flex items-center justify-between relative px-4"
            >
              <!-- 刪除二次確認(右往左滑入) -->
              <button
                @click="deleteConfirm(index)"
                class="
                  absolute
                  z-10
                  top-0
                  right-0
                  h-full
                  bg-danger
                  flex
                  items-center
                  justify-center
                  w-24
                  rounded-l-xl
                  transition-transform
                  duration-300
                  transform
                "
                :class="[item.deleteFlag ? 'translate-x-0' : 'translate-x-full']"
              >
                <p class="text-white font-bold">刪除</p>
              </button>
              <!-- end 刪除二次確認 end -->
              <div class="w-12 h-12 rounded-xl overflow-hidden shadow-md flex-shrink-0">
                <img class="object-cover h-full w-full" :src="item.img" />
              </div>
              <div class="text-left flex-grow px-4">
                <p class="">{{ item.title }}</p>
                <div class="text-xs text-gray-400">
                  <span>{{ item.details.spec1 }}</span>
                  <span v-if="item.details.spec2">{{ ' / ' + item.details.spec2 }}</span>
                  <span v-if="item.details.spec3">{{ ' / ' + item.details.spec3 }}</span>
                </div>
                <p class="text-xs text-gray-400">NT${{ (item.details.sprice * item.qty) | currency }}</p>
              </div>
              <div class="flex items-center space-x-2 flex-shrink-0">
                <vs-button @click="qty('minus', index)" transparent color="success" size="mini">
                  <span class="text-base">-</span>
                </vs-button>
                <span>{{ item.qty }}</span>
                <vs-button @click="qty('plus', index)" transparent color="success" size="mini">
                  <span class="text-base">+</span>
                </vs-button>
              </div>
            </div>
            <div v-if="orderSummary.cart.length === 0" key="noAnyProducts" class="flex items-center justify-center h-full text-gray-divide">
              尚無任何訂單項目
            </div>
          </transition-group>
          <div class="border-t-2 border-gray-background flex flex-col items-center justify-center p-4">
            <!-- 價格列表 *手機版未展開時隱藏 -->
            <div class="w-full space-y-3 mb-4" :class="{ 'hidden md:block': !mobile_summary_toggler }">
              <!-- 小計 -->
              <div class="order_summary">
                <div class="left">小計</div>
                <div class="right">NT${{ _subtotal || 0 | currency }}</div>
              </div>
              <!-- 折扣金額列表 -->
              <div v-for="(discount, index) in orderSummary.discountAry" :key="index" class="order_summary">
                <div class="left">{{ discount.title }}</div>
                <!-- 編輯折扣 tooltip -->
                <div class="right flex items-center justify-end">
                  <vs-tooltip shadow not-hover v-model="discount.popup">
                    <button @click=";(discount.popup = !discount.popup), openEditDiscount(discount)" class="text-success">
                      - NT${{ discount.discountNumber | currency }}
                    </button>

                    <template #tooltip>
                      <ValidationObserver v-slot="{ invalid }">
                        <div class="grid grid-cols-2 gap-4 items-center text-gray-text_light">
                          <p>折扣名稱</p>
                          <vs-input
                            type="text"
                            v-model.trim="orderSummary.editDiscount.title"
                            maxlength="10"
                            placeholder="例如：生日優惠"
                          />
                          <p>折扣金額</p>
                          <ValidationProvider
                            tag="div"
                            class="relative"
                            name="折扣金額"
                            rules="required|numeric|min_value:1"
                            v-slot="{ errors }"
                          >
                            <div class="flex items-center border border-gray-border rounded-xl pl-3 space-x-1 overflow-hidden h-10">
                              <div class="w-14">
                                <span>- NT$</span>
                              </div>
                              <input
                                type="number"
                                placeholder="0"
                                class="w-full h-full"
                                v-model.number="orderSummary.editDiscount.discountNumber"
                              />
                            </div>
                            <errorDiv>{{ errors[0] }}</errorDiv>
                          </ValidationProvider>
                          <vs-button @click="deleteDiscount(index)" color="danger" transparent>刪除</vs-button>
                          <vs-button @click="changeDiscount(invalid, index)" color="success" :disabled="invalid">修改</vs-button>
                        </div>
                      </ValidationObserver>
                    </template>
                  </vs-tooltip>
                </div>
                <!-- 編輯折扣 tooltip end -->
              </div>
              <!-- 運費列表 -->
              <div v-for="(shipping, index) in orderSummary.shippingAry" :key="'shipping' + index" class="order_summary">
                <div class="left">{{ shipping.title }}</div>
                <!-- 編輯運費 tooltip -->
                <div class="right flex items-center justify-end">
                  <vs-tooltip shadow not-hover v-model="shipping.popup">
                    <button @click=";(shipping.popup = !shipping.popup), openEditShipping(shipping)" class="text-success">
                      NT${{ shipping.shippingNumber | currency }}
                    </button>

                    <template #tooltip>
                      <ValidationObserver v-slot="{ invalid }">
                        <div class="grid grid-cols-2 gap-4 items-center text-gray-text_light">
                          <!-- <p>物流公司</p>
                        <vs-input
                          type="text"
                          v-model.trim="orderSummary.editShipping.title"
                          maxlength="10"
                          placeholder="例如：黑貓宅急便"
                        /> -->
                          <p>運費金額</p>
                          <ValidationProvider
                            tag="div"
                            class="relative"
                            name="運費金額"
                            rules="required|numeric|min_value:1"
                            v-slot="{ errors }"
                          >
                            <div class="flex items-center border border-gray-border rounded-xl pl-3 space-x-1 overflow-hidden h-10">
                              <div class="w-14">
                                <span>NT$</span>
                              </div>
                              <input
                                type="number"
                                placeholder="0"
                                class="w-full h-full"
                                v-model.number="orderSummary.editShipping.shippingNumber"
                              />
                            </div>
                            <errorDiv>{{ errors[0] }}</errorDiv>
                          </ValidationProvider>
                          <vs-button @click="deleteShipping(index)" color="danger" transparent>刪除</vs-button>
                          <vs-button @click="changeShipping(invalid, index)" color="success" :disabled="invalid">修改</vs-button>
                        </div>
                      </ValidationObserver>
                    </template>
                  </vs-tooltip>
                </div>
                <!-- 編輯運費 tooltip end -->
              </div>
              <!-- 新增折扣按鈕 -->
              <div v-if="orderSummary.cart.length > 0 && orderSummary.discountAry.length < 1" class="flex items-center justify-end">
                <!-- 新增折扣 tooltip -->
                <vs-tooltip shadow not-hover v-model="orderSummary.newDiscount.popup">
                  <button
                    @click="orderSummary.newDiscount.popup = !orderSummary.newDiscount.popup"
                    class="text-success flex items-center space-x-1"
                  >
                    <svg-icon icon-class="add" className="success xs"></svg-icon>
                    <span>新增折扣</span>
                  </button>

                  <template #tooltip>
                    <ValidationObserver v-slot="{ invalid }">
                      <div class="grid grid-cols-2 gap-4 items-center text-gray-text_light">
                        <p>折扣名稱</p>
                        <vs-input type="text" v-model.trim="orderSummary.newDiscount.title" maxlength="10" placeholder="例如：生日優惠" />
                        <p>折扣金額</p>
                        <ValidationProvider
                          tag="div"
                          class="relative"
                          name="折扣金額"
                          rules="required|numeric|min_value:1"
                          v-slot="{ errors }"
                        >
                          <div class="flex items-center border border-gray-border rounded-xl pl-3 space-x-1 overflow-hidden h-10">
                            <div class="w-14">
                              <span>- NT$</span>
                            </div>
                            <input
                              type="number"
                              placeholder="0"
                              class="w-full h-full"
                              v-model.number="orderSummary.newDiscount.discountNumber"
                            />
                          </div>
                          <errorDiv>{{ errors[0] }}</errorDiv>
                        </ValidationProvider>
                        <!-- <vs-button @click="orderSummary.newDiscount.popup = false" transparent>取消</vs-button> -->
                        <vs-button class="col-span-2" @click="newDiscount(invalid)" color="success" :disabled="invalid">確定</vs-button>
                      </div>
                    </ValidationObserver>
                  </template>
                </vs-tooltip>
                <!-- 新增折扣 tooltip end -->
              </div>
              <!-- 新增運費按鈕 -->
              <div v-if="orderSummary.cart.length > 0 && orderSummary.shippingAry.length < 1" class="flex items-center justify-end">
                <!-- 新增運費 tooltip -->
                <vs-tooltip shadow not-hover v-model="orderSummary.shippingCost.popup">
                  <button
                    @click="orderSummary.shippingCost.popup = !orderSummary.shippingCost.popup"
                    class="text-success flex items-center space-x-1"
                  >
                    <svg-icon icon-class="add" className="success xs"></svg-icon>
                    <span>新增運費</span>
                  </button>

                  <template #tooltip>
                    <ValidationObserver v-slot="{ invalid }">
                      <div class="grid grid-cols-2 gap-4 items-center text-gray-text_light">
                        <!-- <p>物流公司</p>
                      <vs-input type="text" v-model.trim="orderSummary.shippingCost.title" maxlength="10" placeholder="例如：黑貓宅急便" /> -->
                        <p>運費金額</p>
                        <ValidationProvider
                          tag="div"
                          class="relative"
                          name="運費金額"
                          rules="required|numeric|min_value:1"
                          v-slot="{ errors }"
                        >
                          <div class="flex items-center border border-gray-border rounded-xl pl-3 space-x-1 overflow-hidden h-10">
                            <div class="w-14">
                              <span>NT$</span>
                            </div>
                            <input
                              type="number"
                              placeholder="0"
                              class="w-full h-full"
                              v-model.number="orderSummary.shippingCost.shippingNumber"
                            />
                          </div>
                          <errorDiv>{{ errors[0] }}</errorDiv>
                        </ValidationProvider>
                        <!-- <vs-button @click="orderSummary.shippingCost.popup = false" transparent>取消</vs-button> -->
                        <vs-button class="col-span-2" @click="newShipping(invalid)" color="success" :disabled="invalid">確定</vs-button>
                      </div>
                    </ValidationObserver>
                  </template>
                </vs-tooltip>
                <!-- 新增運費 tooltip end -->
              </div>
              <!-- 總計 -->
              <div class="order_summary font-bold text-gray-text_dark">
                <div class="left">總計</div>
                <div class="right">NT${{ _total || 0 | currency }}</div>
              </div>
            </div>
            <vs-button
              v-if="!orderConfirmToggler"
              @click=";(orderConfirmToggler = true), (mobile_summary_toggler = false)"
              :disabled="orderSummary.cart.length === 0"
              color="success"
              class="w-full"
              size="large"
              >下一步</vs-button
            >
            <vs-button
              v-else
              @click="placeOrder(invalid)"
              ref="placeOrderBtn"
              :disabled="orderSummary.cart.length === 0 || invalid"
              color="success"
              class="w-full"
              size="large"
              >創建訂單</vs-button
            >
          </div>
        </div>
      </div>
    </ValidationObserver>
    <!-- 指定會員 dialog -->
    <vs-dialog scroll overflow-hidden blur v-model="orderConfirm.member_dialog" not-center>
      <template #header>
        <h3 class="h3">指定會員</h3>
      </template>

      <div class="my-4 text-sm text-gray-text_light space-y-4">
        <vs-input
          placeholder="以姓名、電話或電子郵件來搜尋"
          v-model="searchMember"
          @blur="search_member"
          @keypress.enter="search_member"
        ></vs-input>

        <ul v-if="orderConfirm.memberList.length > 0" class="flex flex-col items-start space-y-2">
          <li
            v-for="item in orderConfirm.memberList"
            :key="item.smno"
            class="w-full border border-transparent rounded-xl px-4 py-2 transition-colors flex"
            :class="{ 'border-success': orderConfirm.member_id_input === item.smno }"
          >
            <vs-radio success v-model="orderConfirm.member_id_input" :val="item.smno" class="justify-start">
              <div class="flex flex-col">
                <p class="font-bold">{{ item.uname }}</p>
                <p>{{ item.email }}</p>
              </div>
            </vs-radio>
          </li>
        </ul>

        <p class="text-center text-gray-400 text-base" v-else>尚無任何會員</p>

        <!-- pagination -->
        <div v-if="orderConfirm.memberPagination.tpages > 1" class="flex flex-col items-center space-y-2">
          <vs-pagination
            @click.native="changePagination"
            not-arrows
            v-model="orderConfirm.currentPage"
            :length="orderConfirm.memberPagination.tpages"
          />
        </div>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="orderConfirm.member_dialog = false">取消</vs-button>
          <vs-button @click="save_member" size="large" transparent color="success">儲存</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import TW_districts from '@/assets/json/TW_districts.json' // 台灣縣市 郵遞區號 列表
import dropdown from '@/components/dropdown'
import countries from '@/assets/json/en_zh_country.json' // 國家地區列表
export default {
  name: 'create_order',
  components: {
    dropdown,
  },
  data() {
    return {
      TW_districts: TW_districts, // 台灣縣市 郵遞區號 列表
      countries: countries, // 國家地區列表
      is_API_loaded: false,
      products: [],
      pagination: [],
      searchTitle: '', // 搜尋商品標題
      searchTitleBak: '', // 搜尋商品標題備份
      searchMember: '', // 搜尋會員標題
      searchMemberBak: '', // 搜尋會員標題備份
      dropdownIndex: null,
      mobile_summary_toggler: false, // 手機版訂單項目展開/收合
      // 訂單項目
      orderSummary: {
        cart: [],
        // 折扣列表
        discountAry: [],
        // 新折扣
        newDiscount: {
          popup: false,
          title: '',
          discountNumber: '',
        },
        // 修改折扣
        editDiscount: {
          title: '',
          discountNumber: '',
        },
        // 運費列表
        shippingAry: [],
        // 運費
        shippingCost: {
          popup: false,
          title: '',
          shippingNumber: '',
        },
        // 修改運費
        editShipping: {
          title: '',
          shippingNumber: '',
        },
      },
      // 訂單確認
      orderConfirm: {
        member_dialog: false,
        memberShow: false, // 判斷會員顯示新增會員還是編輯
        memberList: [], // 全部會員列表
        memberPagination: {}, // 會員列表頁籤
        currentPage: 1,
        member_id_input: '',
        member_id_show: '',
        member_show_data: {
          uname: '',
          phone_code: '+886,Taiwan',
          mp: '',
          email: '',
        },
        address_homeDelivery: {
          phone_code: '+886,Taiwan',
          runame: '',
          rmp: '',
          remail: '',
          rshit: '台灣 Taiwan',
          rcity: '',
          rarea: '',
          rzipcode: '',
          raddress1: '',
          raddress2: '',
        },
        address_convenience: {
          phone_code: '+886,Taiwan',
          runame: '',
          rmp: '',
          remail: '',
        },
        note: '',
        thirdData: {}, // 讀取商家金流物流資訊
        paymentVal: 'cash', // 被選擇的付款方式 spno
        paymentObj: {
          pthird: 0,
          pterms: 7,
        }, // 被選擇的付款方式 整個obj
        payment_show: true, // 若付款方式為貨到付款就隱藏 false
        shippingVal: '不需送貨', // 被選擇的送貨方式 slno
        shippingObj: {}, // 被選擇的送貨方式 整個obj
        shipping_show: '', // convenience_delivery or home_delivery
      },
      // 超商資料
      convenienceData: {
        sname: '',
        sid: '',
        logistics: '',
      },

      orderConfirmToggler: false, // 開啟訂單確認頁面
    }
  },
  computed: {
    // 篩選出鄉鎮市區
    _district() {
      let city = this.orderConfirm.address_homeDelivery.rcity

      if (city) return this.TW_districts.find((item) => item.name === city)
      else return null
    },
    // 小計
    _subtotal() {
      if (this.orderSummary.cart.length > 0) {
        const initialValue = 0
        const subtotal = this.orderSummary.cart.reduce((accumulator, item) => {
          return accumulator + item.details.sprice * item.qty
        }, initialValue)
        return subtotal
      }
    },
    // 總計
    _total() {
      let discount = 0
      let shipping = 0
      if (this.orderSummary.discountAry.length > 0) discount = this.orderSummary.discountAry[0].discountNumber
      if (this.orderSummary.shippingAry.length > 0) shipping = this.orderSummary.shippingAry[0].shippingNumber
      return this._subtotal - discount + shipping
    },
  },
  created() {
    this.getProductList()
    this.loadThird()
    // 監聽回傳回來的超商資料
    window.addEventListener('message', this.getStoreData)
    // 重整時跳出警告視窗
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },
  mounted() {
    document.addEventListener('click', this.mouseClick)
  },
  watch: {
    // 折扣不能大於小計
    'orderSummary.newDiscount.discountNumber'(newValue) {
      if (newValue > this._subtotal) {
        this.orderSummary.newDiscount.discountNumber = this._subtotal
      }
    },
    // 折扣不能大於小計
    'orderSummary.editDiscount.discountNumber'(newValue) {
      if (newValue > this._subtotal) {
        this.orderSummary.editDiscount.discountNumber = this._subtotal
      }
    },
    // 訂單項目清空時，折扣和運費歸零
    'orderSummary.cart'(newValue) {
      if (newValue.length === 0) {
        this.orderSummary.discountAry = []
        this.orderSummary.shippingAry = []
        this.orderConfirmToggler = false
      }
    },
    // 去除電話號碼首字 0
    'orderConfirm.address_homeDelivery.rmp'(value) {
      if (value.substr(0, 1) === '0') {
        this.orderConfirm.address_homeDelivery.rmp = value.slice(1)
      }
    },
  },
  methods: {
    // 3.3.2.1 讀取商品列表(第一層商品名稱、第二層規格)
    getProductList() {
      this.$axios({
        url: 'front/store/product/getProductL1L2List.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          title: this.searchTitle,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.products = res.data.Data
          this.pagination = res.data.Page
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 新增商品到訂單項目
    addToCart(product, details) {
      this.dropdownIndex = null
      // 找尋重複商品規格的index
      let index = this.orderSummary.cart.findIndex((item) => item.details.spsno === details.spsno)

      // 若有找到，qty + 1
      if (index >= 0) {
        this.orderSummary.cart[index].qty++
      } else {
        // 沒找到，新增一個新的
        this.orderSummary.cart.push({
          img: product.img,
          title: product.title,
          deleteFlag: false, // 刪除訂單項目二次確認(右往左滑入的標籤)
          qty: 1,
          details: details,
        })
      }
    },
    // 數量的加跟減
    qty(status, index) {
      // status plus or minus
      let item = this.orderSummary.cart[index]
      if (status === 'plus') {
        item.qty++
      }

      if (status === 'minus') {
        // 若數量等於1，將要被設為0時，彈出刪除確認。
        if (item.qty === 1) {
          window.addEventListener('click', this.handleClick)
          setTimeout(() => {
            item.deleteFlag = true
          }, 100)
          return
        }
        item.qty--
      }
    },
    // 刪除二次確認
    deleteConfirm(index) {
      this.orderSummary.cart.splice(index, 1)
      window.removeEventListener('click', this.handleClick)
    },
    // 刪除二次確認時監聽點擊
    handleClick(e) {
      this.orderSummary.cart.forEach((item) => {
        item.deleteFlag = false
      })
    },
    // mounted監聽點擊
    mouseClick(e) {
      this.dropdownIndex = null
    },
    showDropdown(index) {
      setTimeout(() => {
        this.dropdownIndex = index
      }, 10)
    },
    // 新增折扣
    newDiscount(invalid) {
      if (invalid) return
      this.orderSummary.newDiscount.popup = false
      setTimeout(() => {
        this.orderSummary.discountAry.push({
          title: this.orderSummary.newDiscount.title || '折扣',
          discountNumber: this.orderSummary.newDiscount.discountNumber,
          popup: false,
        })
        this.orderSummary.newDiscount.title = ''
        this.orderSummary.newDiscount.discountNumber = ''
      }, 100)
    },
    // 新增運費
    newShipping(invalid) {
      if (invalid) return
      this.orderSummary.shippingCost.popup = false
      setTimeout(() => {
        this.orderSummary.shippingAry.push({
          title: this.orderSummary.shippingCost.title || '運費',
          shippingNumber: this.orderSummary.shippingCost.shippingNumber,
          popup: false,
        })
        this.orderSummary.shippingCost.title = ''
        this.orderSummary.shippingCost.shippingNumber = ''
      }, 100)
    },
    // 開啟編輯折扣
    openEditDiscount(discount) {
      this.orderSummary.editDiscount.title = discount.title
      this.orderSummary.editDiscount.discountNumber = discount.discountNumber
    },
    // 修改折扣
    changeDiscount(invalid, index) {
      if (invalid) return
      let discountAry = this.orderSummary.discountAry[index]
      discountAry.title = this.orderSummary.editDiscount.title
      discountAry.discountNumber = this.orderSummary.editDiscount.discountNumber
      discountAry.popup = false
    },
    // 刪除折扣
    deleteDiscount(index) {
      this.orderSummary.discountAry[index].popup = false

      setTimeout(() => {
        this.orderSummary.discountAry.splice(index, 1)
      }, 300)
    },
    // 開啟編輯運費
    openEditShipping(shipping) {
      this.orderSummary.editShipping.title = shipping.title
      this.orderSummary.editShipping.shippingNumber = shipping.shippingNumber
    },
    // 修改運費
    changeShipping(invalid, index) {
      if (invalid) return
      let shippingAry = this.orderSummary.shippingAry[index]
      shippingAry.title = this.orderSummary.editShipping.title
      shippingAry.shippingNumber = this.orderSummary.editShipping.shippingNumber
      shippingAry.popup = false
    },
    // 刪除運費
    deleteShipping(index) {
      this.orderSummary.shippingAry[index].popup = false

      setTimeout(() => {
        this.orderSummary.shippingAry.splice(index, 1)
      }, 300)
    },
    // 搜尋商品標題
    search() {
      if (this.searchTitle === this.searchTitleBak) return
      this.getProductList()
      this.searchTitleBak = this.searchTitle
    },
    search_member() {
      if (this.searchMember === this.searchMemberBak) return
      this.orderConfirm.currentPage = 1
      this.loadCustomerList()
      this.searchMemberBak = this.searchMember
    },
    // 開啟會員dialog
    edit_member() {
      this.loadCustomerList()
      this.orderConfirm.member_id_input = this.orderConfirm.member_id_show
      this.orderConfirm.member_dialog = true
    },
    save_member() {
      this.orderConfirm.member_dialog = false
      this.orderConfirm.member_id_show = this.orderConfirm.member_id_input

      if (this.orderConfirm.member_id_input === '無' || this.orderConfirm.member_id_input === '') {
        this.orderConfirm.memberShow = false
        this.orderConfirm.member_show_data = {}
      } else {
        this.loadCustomer()
      }
    },
    // 3.2.1.1 讀取會員列表
    loadCustomerList() {
      this.$axios({
        url: 'front/store/member/getMemberList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          page: this.orderConfirm.currentPage,
          uname: this.searchMember,
          eactivate: -99,
          month: -99,
          sex: -99,
          opays: -99,
          ocounts: -99,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.orderConfirm.memberList = res.data.Data
          this.orderConfirm.memberPagination = res.data.Page
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.2.1.2 會員資料-讀取
    loadCustomer() {
      this.$axios({
        url: 'front/store/member/getMemberUnit.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          smno: this.orderConfirm.member_id_input,
        },
      }).then((res) => {
        if (res.data.Success) {
          let data = res.data.Data
          this.orderConfirm.memberShow = true

          // 會員資料
          this.orderConfirm.member_show_data = {
            uname: data.uname,
            email: data.email,
            mp: data.mp,
            phone_code: '+886,Taiwan',
            vip: data.vip,
          }

          let address_homeDelivery = this.orderConfirm.address_homeDelivery
          let address_convenience = this.orderConfirm.address_convenience

          // 找出區碼
          if (data.rmp) {
            for (let i = 5; i > 1; i--) {
              // 迴圈四次 i =  5,4,3,2
              let confirm = false
              let code = data.rmp.substr(0, i) // 取得+區碼四位 例如 +1876 牙買加
              this.countries.forEach((country) => {
                if (country.code === code) {
                  confirm = true
                  address_homeDelivery.phone_code = `${country.code},${country.en}` // 取得配對到的國家
                  address_convenience.phone_code = `${country.code},${country.en}` // 取得配對到的國家

                  data.rmp = data.rmp.replace(code, '') // 去除區碼
                }
              })
              if (confirm) {
                break
              }
            }
          }

          console.log(data)

          address_convenience.runame = data.runame
          address_convenience.rmp = data.rmp
          address_convenience.remail = data.remail

          for (let key in address_homeDelivery) {
            if (key === 'phone_code') continue
            address_homeDelivery[key] = data[key]
          }
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.3.2.6 新增訂單資料
    placeOrder(invalid) {
      if (invalid) return

      let oSummary = this.orderSummary
      let oConfirm = this.orderConfirm

      // 表單驗證 start

      // 若選擇店到店，但沒選擇門市
      if (oConfirm.shippingObj.logistics >= 1 && oConfirm.shippingObj.logistics <= 4 && !this.convenienceData.sid) {
        this.$myVS.openNoti('請選擇超商門市', '', 'danger', 10000)
        return
      }

      // 若付款方式為串接第三方，需判斷顧客資料為必填
      // if (oConfirm.paymentObj.pthird !== 0) {
      //   if (oConfirm.member_show_data.email === '' || oConfirm.member_show_data.uname === '' || oConfirm.member_show_data.mp === '') {
      //     this.$myVS.openNoti('請填寫顧客資料。', '若付款方式為經由第三方付款(例如: 綠界/藍新 等等)，需填入顧客資料。', 'danger', 20000)
      //     return
      //   }
      // }

      // 表單驗證 end

      const loading = this.$vs.loading({
        target: this.$refs.placeOrderBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let pdata = [] // 訂單項目 JSON
      oSummary.cart.forEach((item) => {
        pdata.push({
          spsno: item.details.spsno,
          amount: item.qty,
        })
      })

      let runame,
        rmp,
        remail,
        rshit,
        rcity,
        rarea,
        rzipcode,
        raddress1,
        raddress2 = ''
      // 若送貨方式為超商
      if (oConfirm.shippingObj.logistics >= 1 && oConfirm.shippingObj.logistics <= 4) {
        runame = oConfirm.address_convenience.runame
        remail = oConfirm.address_convenience.remail
        rmp = oConfirm.address_convenience.phone_code.split(',')[0] + oConfirm.address_convenience.rmp
      } else if (oConfirm.shippingObj.logistics === 0 && oConfirm.shippingObj.lthird === 2) {
        // 若為 ezship
        runame = oConfirm.address_convenience.runame
        remail = oConfirm.address_convenience.remail
        rmp = oConfirm.address_convenience.phone_code.split(',')[0] + oConfirm.address_convenience.rmp
        //* ezship要把logistics取代
        oConfirm.shippingObj.logistics = this.convenienceData.logistics
      } else if (oConfirm.shippingObj.logistics >= 5 && oConfirm.shippingObj.logistics <= 7) {
        // 若送貨方式為宅配
        runame = oConfirm.address_homeDelivery.runame
        rmp = oConfirm.address_homeDelivery.phone_code.split(',')[0] + oConfirm.address_homeDelivery.rmp
        remail = oConfirm.address_homeDelivery.remail
        rshit = oConfirm.address_homeDelivery.rshit
        rcity = oConfirm.address_homeDelivery.rcity
        rarea = oConfirm.address_homeDelivery.rarea
        rzipcode = oConfirm.address_homeDelivery.rzipcode
        raddress1 = oConfirm.address_homeDelivery.raddress1
        raddress2 = oConfirm.address_homeDelivery.raddress2
      }

      this.$axios({
        url: 'front/store/morder/setMOrderData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          pdata: JSON.stringify(pdata),
          lpays: oSummary.shippingAry[0] ? oSummary.shippingAry[0].shippingNumber : 0,
          dname1: oSummary.discountAry[0] ? oSummary.discountAry[0].title : '',
          discount1: oSummary.discountAry[0] ? oSummary.discountAry[0].discountNumber : 0,
          discount2: 0,
          discount3: 0,
          discount4: 0,
          discount5: 0,
          pterms: oConfirm.paymentObj.pterms, // 付款方式
          pthird: oConfirm.paymentObj.pthird, // 第三方金流
          logistics: oConfirm.shippingObj.logistics, // 送貨方式
          lthird: oConfirm.shippingObj.lthird, // 第三方物流
          slno: oConfirm.shippingObj.slno, // 運送方式的key
          uname: oConfirm.member_show_data.uname,
          mp: oConfirm.member_show_data.mp,
          email: oConfirm.member_show_data.email,
          runame: runame,
          rmp: rmp,
          remail: remail,
          rshit: rshit,
          rcity: rcity,
          rarea: rarea,
          rzipcode: rzipcode,
          raddress1: raddress1,
          raddress2: raddress2,
          store_name: this.convenienceData.sname, // 門市名稱
          store_id: this.convenienceData.sid, // 門市店號
          land: 0, //  送貨島嶼
          temp: 0, // 溫層
          spec: 0, // 包裹尺寸
          ltime: 0, // 送貨時間
          onote: oConfirm.note,
          intype1: 0, // 發票
          intype2: 0, // 發票2
          cnamne: '', // 公司抬頭
          cvat: '', // 統一編號
        },
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          console.log('創建訂單', res)
          this.$myVS.openNoti(res.data.Message, '', undefined)

          // 回復data為初始值
          Object.assign(this.$data, this.$options.data.call(this))
          // 還原初始值後，需重新取得資料
          this.getProductList()
          this.loadThird()

          // if (oConfirm.paymentObj.pterms !== 7 && oConfirm.paymentObj.pterms !== 10) {
          //   this.thirdPayment(res.data.Data.smono) // 導去金流付款頁面 (訂單編號)
          // }
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.3.2.4 讀取商家金流物流資訊
    loadThird() {
      this.$axios({
        url: 'front/store/pterms/getPtermsLogisticsList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.orderConfirm.thirdData = res.data.Data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.3.2.7 建立第三方金流串接
    // thirdPayment(orderNum) {
    //   console.log(orderNum)

    //   const kcval = this.$aes.encrypt(
    //     this.$store.state.login.userInfo.enval1,
    //     this.$store.state.login.userInfo.enval2,
    //     this.$store.state.login.userInfo.stoken
    //   )

    //   console.log('kcval', kcval)

    //   setTimeout(() => {
    //     location.href = `https:///front/store/morder/setPtermsThirdApi.php?
    //     smono=${orderNum}
    //     &sn=9BWtk/KrOOIvbznZtLaqWLajdDzw63oyRI57x9VsRr8=
    //     &stoken=${this.$store.state.login.userInfo.stoken}
    //     &kcval=${kcval}`
    //   }, 5000)
    // },
    // 彈出選擇門市新視窗
    getStorePopup() {
      const lthird = this.orderConfirm.shippingObj.lthird
      const logistics = this.orderConfirm.shippingObj.logistics

      let routeData = this.$router.resolve({
        name: 'thirdlogistics',
        query: {
          lthird: lthird, // 送貨方式 1 綠界 2 ezship
          logistics: lthird === 1 ? logistics : 0, // 送貨方式 ezship 只需傳0
        },
      })
      window.open(routeData.href, '_blank')
    },
    // 取得超商資料
    getStoreData(e) {
      if (e.data.convenienceData) {
        this.convenienceData = e.data.convenienceData.payload
      }
    },
    // 取消選擇會員
    customerUnChoose() {
      const oConfirm = this.orderConfirm
      const member_show_data = (oConfirm.memberShow = false)
      oConfirm.member_show_data = {
        uname: '',
        email: '',
        mp: '',
        phone_code: '+886,Taiwan',
        vip: '',
      }
    },
    // 選擇送貨方式時執行
    handleShippingSelect(slno) {
      // 每次重新選擇時，門市資訊清空
      this.convenienceData = {}

      if (slno === '不需送貨') {
        this.orderConfirm.shippingObj = {}
        this.orderConfirm.shipping_show = ''
        this.orderConfirm.payment_show = true

        return
      }

      // 找出符合的陣列
      this.orderConfirm.shippingObj = this.orderConfirm.thirdData.arr2.find((item) => item.slno === slno)

      console.log(this.orderConfirm.shippingObj.logistics)

      // 判斷送貨是超商店到店還是宅配
      // 送貨方式 0.無 1.7-11 2.全家 3.萊爾富 4.OK  5.黑貓宅配  6.賣家宅配 7.海外配送
      if (this.orderConfirm.shippingObj.logistics >= 1 && this.orderConfirm.shippingObj.logistics <= 4) {
        this.orderConfirm.shipping_show = 'convenience_delivery'
      } else if (this.orderConfirm.shippingObj.logistics === 0 && this.orderConfirm.shippingObj.lthird === 2) {
        // 判斷 ezship
        this.orderConfirm.shipping_show = 'convenience_delivery'
      } else {
        this.orderConfirm.shipping_show = 'home_delivery'
      }

      // 判斷若是貨到付款，就隱藏付款方式
      if (this.orderConfirm.shippingObj.pterms === 6) {
        this.orderConfirm.payment_show = false
        // 付款方式改為 6 取貨付款
        this.orderConfirm.paymentObj = {
          pthird: 0,
          pterms: 6,
        }
      } else {
        this.orderConfirm.payment_show = true
        this.orderConfirm.paymentObj = {
          pthird: 0,
          pterms: 7,
        }
      }
    },
    // 選擇付款方式時執行
    handlePaymentSelect(spno) {
      // 若為線下現金付款
      if (spno === 'cash') {
        this.orderConfirm.paymentObj = {
          pthird: 0,
          pterms: 7,
        }
      } else if (spno === 'offline_creditCard') {
        // 若為線下信用卡付款
        this.orderConfirm.paymentObj = {
          pthird: 0,
          pterms: 10,
        }
      } else {
        // 若為串接第三方，找出符合的陣列
        this.orderConfirm.paymentObj = this.orderConfirm.thirdData.arr1.find((item) => item.spno === spno)
      }
    },
    // 選擇縣市時，改變鄉鎮市區和郵遞區號
    cityHandler(e) {
      const city = e
      let cityObj = this.TW_districts.find((item) => item.name === city)
      this.orderConfirm.address_homeDelivery.rarea = cityObj.districts[0].name
      this.orderConfirm.address_homeDelivery.rzipcode = cityObj.districts[0].zip
    },
    // 選擇鄉鎮市區時，改變郵遞區號
    districtHandler(e) {
      const city = this.orderConfirm.address_homeDelivery.rcity
      let obj = this.TW_districts.find((item) => item.name === city).districts.find((district) => district.name === e)
      this.orderConfirm.address_homeDelivery.rzipcode = obj.zip
    },
    // 改變頁籤時觸發
    changePagination() {
      this.loadCustomerList()
    },

    beforeWindowUnload(e) {
      e.returnValue = '要重新載入網站嗎？系統可能不會儲存你所做的變更。'
    },
  },
  //
  beforeRouteLeave(to, from, next) {
    // 離開路由前若購物車有商品
    if (this.orderSummary.cart.length > 0) {
      const answer = window.confirm('確定要離開嗎？系統可能不會儲存你所做的變更。')
      answer ? next() : next(false)
    } else {
      next()
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClick)
    document.removeEventListener('click', this.mouseClick)
    window.removeEventListener('message', this.getStoreData)
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  @apply bg-white w-full rounded-xl flex divide-x-2 divide-gray-background;
  height: calc(100vh - 64px - 25px - 20px - 5px);
}

.image_item {
  @apply relative
     cursor-pointer
     bg-transparent
     transition-all duration-300
    transform  hover:translate-y-1;

  .image_item_content {
    @apply absolute top-0 left-0 right-0 bottom-0;

    .image_item_inner {
      @apply h-full w-full flex flex-col rounded-xl overflow-hidden
    divide-gray-background divide-y-2
    border-2 border-gray-background
    shadow-xl hover:shadow-none transition-all duration-300
    absolute;

      .image {
        @apply object-cover w-full;
      }
    }

    .product_name {
      @apply flex-grow flex items-center justify-center px-4 py-2;
    }
  }
}

.order_summary_wrap {
  @apply md:w-1/3 flex flex-col
  transition-all duration-300;
  @screen md {
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    @apply fixed z-2000
    w-full bottom-0 left-0
    bg-white
    rounded-t-xl;

    box-shadow: 0px 0px 20px -5px rgba($color: #aaaaaa, $alpha: 0.5);
  }
}

.order_summary {
  @apply flex text-right;
  .left {
    @apply w-7/12;
  }
  .right {
    @apply w-5/12;
  }
}

// 訂單確認
.member_box {
  @apply w-full bg-white
    rounded-xl border-2 border-gray-background;
  // transition-all duration-300
  //   shadow-xl hover:shadow-none
  //   transform  hover:translate-y-1
  min-height: 60px;

  .member_box_inner {
    @apply flex flex-col p-4 items-start h-full w-full
    space-y-4;
  }
}
</style>
